import { createAsyncThunk } from "@reduxjs/toolkit";

export const requestSupportV2 = createAsyncThunk(
  "cart/requestSupportV2",
  async ({ landingId }, thunkAPI) => {
    const state = thunkAPI.getState();

    const response = await fetch(
      `${process.env.BACKEND_API}/graphql`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
        mutation requestSupportV2($landingId: ID!, $firstName: String, $lastName: String, $email: String!, $orderNumber: String!, $subject: String!, $message: String!) {
          requestSupportV2(landingId: $landingId, firstName: $firstName, lastName: $lastName, email: $email, orderNumber: $orderNumber, subject: $subject, message: $message)  
        }
      `,
          variables: {
            landingId,
            email: state.Support.email?.trim(),
            orderNumber: state.Support.orderNumber,
            subject: state.Support.subject.value,
            message: state.Support.message,
          },
        }),
      }
    );

    const { data, errors } = await response.json();
    if (errors) {
      throw new Error(errors.map((item) => item.message).join("\n"));
    }

    const { requestSupportV2 } = data;
    if (!requestSupportV2) {
      throw new Error("Can not sent message!");
    }

    return true;
  }
);
