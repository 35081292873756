const tshirtLikeProductTypes = ['tshirt', 'tshirt legacy', 'crew']

export const getProductSizesOptions = (iPrices, iProductId) => {
  const sizesOrder = ['SM', 'MD', 'LG', 'XL', '2XL']
  let result = []

  iPrices.forEach(({ productType, productId, attributes }) => {
    if (productId !== iProductId || !tshirtLikeProductTypes.includes(productType)) {
      return
    }

    const size = attributes.find(item => item.attribute === 'size')
    if (!size || !size.value) {
      console.error(`Size was not defined for a product (${iProductId})!`)
      return
    }

    result.push({
      value: size.value,
      label: size.value
    })
  })

  return result.sort((a, b) => sizesOrder.indexOf(a.value) - sizesOrder.indexOf(b.value))
}

export const getProductPrice = (prices, selectedAttributes) => {
  let price

  const sizeAttribute = selectedAttributes.find(item => item.attribute === 'size')
  const variantAttribute = selectedAttributes.find(item => item.attribute === 'variant')
  if (variantAttribute && sizeAttribute) {
    // it's a T-Shirt
    prices.forEach(currentPrice => {
      let hasSameVariant = currentPrice.attributes.find(item => item.attribute === 'variant' && item.value === variantAttribute.value)

      if (!hasSameVariant) {
        hasSameVariant = currentPrice.name === variantAttribute.value
      }

      const hasSameSize = currentPrice.attributes.find(item => item.attribute === 'size' && item.value === sizeAttribute.value)

      if (hasSameVariant && hasSameSize) {
        price = currentPrice
      }
    })
  }

  if (variantAttribute && !sizeAttribute) {
    // it's just a default price selection
    prices.forEach(currentPrice => {
      const hasSameVariant =
        currentPrice.attributes.find(item => item.attribute === 'variant' && item.value === variantAttribute.value) || currentPrice.name === variantAttribute.value

      if (hasSameVariant) {
        price = currentPrice
      }
    })
  }

  if (!variantAttribute && sizeAttribute) {
    // it's an Upsell sale, they have just different sizes
    prices.forEach(currentPrice => {
      const hasSameSize = currentPrice.attributes.find(item => item.attribute === 'size' && item.value === sizeAttribute.value)

      if (hasSameSize) {
        price = currentPrice
      }
    })
  }

  if (!variantAttribute && !sizeAttribute) {
    price = prices[0]
    // it's a sticker or totebag, let's select first of them
  }

  return price
}

export const getVariantsWithRandomPrices = prices => {
  const uniqVariants = prices.reduce((accum, current) => {
    const { productType, attributes } = current
    // eslint-disable-next-line default-case
    switch (productType) {
      case 'crew':
      case 'tshirt': {
        if (!accum[current.name]) {
          accum[current.name] = {
            id: current.id,
            name: current.name,
            displayName: current.displayName,
            description: current.description,
            productDisplayName: current.productDisplayName,
            imageUrl: current.imageUrl,
            originalImageUrl: current.originalImageUrl,
            previewImageUrl: current.previewImageUrl,
            price: ((current.price || 0) / 100).toFixed(2),
            discount: ((current.discount || 0) / 100).toFixed(2),
            finalPrice: ((current.price - current.discount) / 100).toFixed(2)
          }
        }
        break
      }

      case 'tshirt legacy': {
        if (attributes) {
          const { productVariantId } = attributes
          if (productVariantId) {
            if (!accum[productVariantId]) {
              accum[productVariantId] = {
                id: productVariantId,
                name: current.name,
                displayName: current.displayName,
                description: current.description,
                productDisplayName: current.productDisplayName,
                imageUrl: current.imageUrl,
                originalImageUrl: current.imageUrl,
                previewImageUrl: current.previewImageUrl,
                price: ((current.price || 0) / 100).toFixed(2),
                discount: ((current.discount || 0) / 100).toFixed(2),
                finalPrice: ((current.price - current.discount) / 100).toFixed(2)
              }
            }
          }
        }
        break
      }
    }
    return accum
  }, {})

  return Object.values(uniqVariants)
}
