import { createSlice } from '@reduxjs/toolkit'

import {
  changePassword,
  customerAccountBecameVip,
  customerAccountCreateSubscription,
  customerAccountDeactivateSubscription,
  customerAccountLogin,
  customerAccountLogout,
  customerAccountOrdersPage,
  customerAccountReactivateSubscription,
  customerAccountRegister,
  customerAccountRefreshToken,
  requestResetPassword,
  resetPassword,
  updateCustomerAccount,
  isZipCodeValid
} from './actions'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    membershipInfo: {
      name: '',
      status: 'ANONYMOUS',
      activeFrom: '',
      nextPaymentDate: '',
      recentPayments: []
    },
    address: '',
    address2: '',
    city: '',
    countryId: 1,
    countryOption: {
      value: 1,
      label: 'United States of America'
    },
    state: '',
    stateOption: undefined,
    zipCode: '',
    isRememberMe: typeof localStorage !== 'undefined' ? !!localStorage.getItem('refreshToken') : false,
    logoutFromAllDevices: false,
    orders: {
      items: [],
      total: 0
    },
    UIState: {
      isLoading: false,
      isResettingPassword: false
    },
    isValid: false,
    pageAfterLogin: '/',
    subscriptionDeclineReason: '',
    validation: {
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      country: true,
      state: false,
      zipCode: {
        isValid: false,
        error: 'Please input your ZIP code'
      }
    }
  },
  reducers: {
    cleanError: (state, action) => {
      delete state.UIState.error
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload
      state.validation.firstName = action.payload !== ''
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
      state.validation.lastName = action.payload !== ''
    },
    setMembershipInfo: (state, action) => {
      state.membershipInfo = action.payload.membershipInfo
    },
    setAddress: (state, action) => {
      state.address = action.payload
      state.validation.address = action.payload !== ''
    },
    setAddress2: (state, action) => {
      state.address2 = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
      state.validation.city = action.payload !== ''
    },
    setCountry: (state, action) => {
      state.countryOption = action.payload
      state.countryId = action.payload.value
      state.validation.country = action.payload !== ''
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload
      state.validation.zipCode = isZipCodeValid(action.payload)
    },
    setState: (state, action) => {
      state.stateOption = action.payload
      state.state = action.payload.label
      state.validation.state = action.payload !== '' && action.payload.label !== ''
    },
    setIsRememberMe: (state, action) => {
      state.isRememberMe = action.payload
    },
    setIsLogoutFromAllDevices: (state, action) => {
      state.logoutFromAllDevices = action.payload
    },
    setPageAfterLogin: (state, action) => {
      state.pageAfterLogin = action.payload
    },
    setSubscriptionDeclineReason: (state, action) => {
      state.subscriptionDeclineReason = action.payload.value
    },
    setMembershipAsTrial: (state, action) => {
      state.membershipInfo.status = 'TRIAL'
    }
  },
  extraReducers: {
    [customerAccountLogin.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountLogin.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.firstName = action.payload.user.firstName
      state.lastName = action.payload.user.lastName
      state.email = action.payload.user.email
      state.phone = action.payload.user.phone
      state.membershipInfo = action.payload.user.membershipInfo
      //
      state.address = action.payload.user.shippingAddress
      state.address2 = action.payload.user.shippingAddress2
      state.city = action.payload.user.shippingCity
      state.state = action.payload.user.shippingState
      state.zipCode = action.payload.user.shippingZipCode
      //
      state.validation.firstName = action.payload.user.firstName !== ''
      state.validation.lastName = action.payload.user.lastName !== ''
      //
      state.validation.address = action.payload.user.address !== ''
      state.validation.city = action.payload.user.city !== ''
      state.validation.state = action.payload.user.state !== ''
      state.validation.zipCode = action.payload.user.zipCode !== ''
    },
    [customerAccountLogin.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [customerAccountCreateSubscription.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountCreateSubscription.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.membershipInfo = action.payload
      delete state.UIState.error
    },
    [customerAccountCreateSubscription.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },
    [customerAccountBecameVip.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountBecameVip.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.membershipInfo = action.payload.subscription
      state.address = action.payload.address.address
      state.address2 = action.payload.address.address2
      state.city = action.payload.address.city
      state.countryId = action.payload.address.countryId
      state.state = action.payload.address.state
      state.zipCode = action.payload.address.zipCode
      state.firstName = action.payload.customerInfo.firstName
      state.lastName = action.payload.customerInfo.lastName
      delete state.UIState.error
    },
    [customerAccountBecameVip.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },
    [customerAccountDeactivateSubscription.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountDeactivateSubscription.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.membershipInfo = action.payload
      delete state.UIState.error
    },
    [customerAccountDeactivateSubscription.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [customerAccountReactivateSubscription.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountReactivateSubscription.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.membershipInfo = action.payload
    },
    [customerAccountReactivateSubscription.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [customerAccountRegister.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountRegister.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.firstName = action.payload.user.firstName
      state.lastName = action.payload.user.lastName
      state.email = action.payload.user.email
      state.phone = action.payload.user.phone
      state.membershipInfo = action.payload.user.membershipInfo
      //
      state.address = action.payload.user.shippingAddress
      state.address2 = action.payload.user.shippingAddress2
      state.city = action.payload.user.shippingCity
      state.state = action.payload.user.shippingState
      state.zipCode = action.payload.user.shippingZipCode
      //
      state.validation.firstName = action.payload.user.firstName !== ''
      state.validation.lastName = action.payload.user.lastName !== ''
      //
      state.validation.address = action.payload.user.address !== ''
      state.validation.city = action.payload.user.city !== ''
      state.validation.state = action.payload.user.state !== ''
      state.validation.zipCode = action.payload.user.zipCode !== ''
    },
    [customerAccountRegister.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [customerAccountRefreshToken.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountRefreshToken.fulfilled]: (state, action) => {
      state.UIState.isLoading = false

      if (action.payload.isAuthorized) {
        state.firstName = action.payload.user.firstName
        state.lastName = action.payload.user.lastName
        state.email = action.payload.user.email
        state.phone = action.payload.user.phone
        state.membershipInfo = action.payload.user.membershipInfo
        //
        state.address = action.payload.user.shippingAddress
        state.address2 = action.payload.user.shippingAddress2
        state.city = action.payload.user.shippingCity
        state.state = action.payload.user.shippingState
        state.zipCode = action.payload.user.shippingZipCode
        //
        state.validation.firstName = action.payload.user.firstName !== ''
        state.validation.lastName = action.payload.user.lastName !== ''
        //
        state.validation.address = action.payload.user.address !== ''
        state.validation.city = action.payload.user.city !== ''
        state.validation.state = action.payload.user.state !== ''
        state.validation.zipCode = action.payload.user.zipCode !== ''
      }
    },
    [customerAccountRefreshToken.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message

      if (typeof localStorage !== 'undefined' && sessionStorage !== 'undefined') {
        localStorage.removeItem('refreshToken')
        sessionStorage.removeItem('refreshToken')
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('expiresIn')
      }
    },

    [customerAccountLogout.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountLogout.fulfilled]: (state, action) => {
      state.firstName = ''
      state.lastName = ''
      state.email = ''
      state.phone = ''
      state.membershipInfo = {
        status: 'ANONYMOUS'
      }
      state.address = ''
      state.address2 = ''
      state.city = ''
      state.countryId = ''
      state.countryOption = ''
      state.state = ''
      state.zipCode = ''
      state.UIState.isLoading = false
    },
    [customerAccountLogout.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [updateCustomerAccount.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [updateCustomerAccount.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
    },
    [updateCustomerAccount.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [requestResetPassword.pending]: (state, action) => {
      state.UIState.isResettingPassword = true
      delete state.UIState.error
    },

    [requestResetPassword.fulfilled]: (state, action) => {
      state.UIState.isResettingPassword = false
    },

    [requestResetPassword.rejected]: (state, action) => {
      state.UIState.isResettingPassword = false
      state.UIState.error = action.error.message
    },

    [resetPassword.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },

    [resetPassword.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
    },

    [resetPassword.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [changePassword.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },

    [changePassword.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
    },

    [changePassword.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [customerAccountOrdersPage.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },

    [customerAccountOrdersPage.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
      state.orders.items = action.payload.items
      state.orders.total = action.payload.total
    },

    [customerAccountOrdersPage.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },
    'login/validateCustomerEmail/pending': (state, action) => {
      state.UIState.isLoading = true
    },
    'login/validateCustomerEmail/fulfilled': (state, action) => {
      state.UIState.isLoading = false
    }
  }
})

export const actionCreators = loginSlice.actions
export const selectLoginState = state => state.LoginPage
export const selectIsMembershipActive = state =>
  state.LoginPage.membershipInfo?.status === 'TRIAL' || state.LoginPage.membershipInfo?.status === 'EXPIRING' || state.LoginPage.membershipInfo?.status === 'ACTIVE'

export const selectors = {
  selectLoginState,
  selectIsMembershipActive
}

export default loginSlice.reducer
