import { createSlice } from "@reduxjs/toolkit";
import { addUpsellToOrder } from "./actions";

const stickerSlice = createSlice({
  name: "sticker",
  initialState: {
    stickerPrice: undefined,
    UIState: {
      error: undefined,
      isLoading: false
    }
  },
  reducers: {
    setPrices: (state, action) => {
      const { prices } = action.payload

      if (!prices.length) {
        throw new Error("Price should be defined if sticker page is used!");
      }

      state.stickerPrice = prices[0]
    },
  },
  extraReducers: {
    [addUpsellToOrder.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [addUpsellToOrder.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [addUpsellToOrder.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    }
  }
});

export const actionCreators = stickerSlice.actions;
export const selectStickerState = state => state.StickerPage;
export const selectors = {
  selectStickerState
};

export default stickerSlice.reducer;
