import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { connect } from "react-redux";
import 'assets/_root.scss'
import 'assets/_normalize.scss'
import { actionCreators } from 'state/HomePage/reducer'
import { actionCreators as upsellCreators } from 'state/UpsellPage/reducer'
import { actionCreators as stickerCreators } from 'state/StickerPage/reducer'
import { actionCreators as upgradeCreators } from 'state/UpgradePage/reducer'
import { useSiteMetadata } from 'hooks/use-site-metadata';

const query = graphql`
  query Products {
    allMarkdownRemark(
      filter: {fields: {slug: {in: ["/", "/upsell/", "/sticker/", "/upgrade/"]}}}
    ) {
      nodes {
        frontmatter {
          MAIN_PRODUCT {
            prices {
              id
              workspaceId
              ownerType
              ownerId
              landingId
              productId
              productTypeV2
              productType
              name
              displayName
              description
              productDisplayName
              sku
              price
              discount
              finalPrice
              memberPrice
              imageUrl
              hasCustomSeo
              seoUrl
              seoTitle
              seoDescription
              attributes {
                productPriceId
                ownerType
                ownerId
                attribute
                value
              }
              addedAt
              createdAt
              updatedAt
              hasCustomShipping
              customShippingPrice
              previewImageUrl
              originalImageUrl
            }
          }
          UPSELL_PRODUCT {
            prices {
              id
              workspaceId
              landingId
              productId
              productType
              name
              displayName
              description
              productDisplayName
              sku
              price
              discount
              imageUrl
              attributes {
                productPriceId
                attribute
                value
              }
              createdAt
              updatedAt
              originalImageUrl
            }
          }
          STICKER_PRODUCT {
            prices {
              id
              workspaceId
              landingId
              productId
              productType
              name
              displayName
              description
              productDisplayName
              sku
              price
              discount
              imageUrl
              originalImageUrl
              createdAt
              updatedAt
            }
          }
          UPGRADE_PRODUCT {
            prices {
              id
              workspaceId
              landingId
              productId
              productType
              name
              displayName
              description
              productDisplayName
              sku
              price
              discount
              finalPrice
              imageUrl
              previewImageUrl
            }
          }
        }
      }
    }
  }
`

const Layout = ({ children, setPrices, setUpsellPrices, setStickerPrices, setUpgradePrices }) => {
  const data = useStaticQuery(query)
  const { shared } = useSiteMetadata()

  useEffect(() => {
    if (setPrices && setUpsellPrices && setStickerPrices && setUpgradePrices) {
      const products = data.allMarkdownRemark.nodes

      const mainProductPrices = products.find(item => !!item.frontmatter.MAIN_PRODUCT)
      const upsellProductPrices = products.find(item => !!item.frontmatter.UPSELL_PRODUCT)
      const stickerProductPrices = products.find(item => !!item.frontmatter.STICKER_PRODUCT)
      const upgradeProductPrices = products.find(item => !!item.frontmatter.UPGRADE_PRODUCT)

      setPrices({
        prices: mainProductPrices?.frontmatter?.MAIN_PRODUCT?.prices || [],
        subscriptionProduct: shared?.SUBSCRIPTION_PRODUCT
      })

      setUpsellPrices({
        prices: upsellProductPrices?.frontmatter?.UPSELL_PRODUCT?.prices || []
      })

      setStickerPrices({
        prices: stickerProductPrices?.frontmatter?.STICKER_PRODUCT?.prices || [],
      })

      setUpgradePrices({
        prices: upgradeProductPrices?.frontmatter?.UPGRADE_PRODUCT?.prices || [{}],
      })
    }
  }, [setPrices, setUpsellPrices, setStickerPrices, setUpgradePrices])

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  setPrices: actionCreators.setPrices,
  setUpsellPrices: upsellCreators.setPrices,
  setStickerPrices: stickerCreators.setPrices,
  setUpgradePrices: upgradeCreators.setPrices
}

export default connect(undefined, mapDispatchToProps)(Layout)