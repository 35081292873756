import { createSlice } from "@reduxjs/toolkit";
import { takePayment, addSubscriptionToCart, removeSubscriptionFromCart } from "./actions";
import { customerAccountLogout } from '../LoginPage/actions'

const isValidCardNumber = (cardNumber) => {
  const cleanNumber = cardNumber.replace(/\s/g, "");
  const universalRegExp = /\b\d{13,18}\b/;

  let isValid = false;
  let error = "Please input Card Number";

  isValid = universalRegExp.test(cleanNumber);

  if (!isValid) {
    error = "Please input correct Card Number";
  }
  return {
    isValid,
    error,
  };
};

const isValidExpiryDate = (expiryDate) => {
  let isValid = false;
  let error = "Please input Expiry Date";
  const reg = /(0[1-9]|10|11|12)\/[0-9]{2}$/;
  isValid = reg.test(expiryDate);
  if (!isValid) {
    error = "Please input correct Expiry Date";
  }

  return {
    isValid,
    error,
  };
};

const isValidCvv = (cvv) => {
  let isValid = false;
  let error = "Please input CVV";
  const reg = /^\d{3}$/;
  isValid = reg.test(cvv);
  if (!isValid) {
    error = "Please input correct cvv";
  }

  return {
    isValid,
    error,
  };
};

const isZipCodeValid = (zipCode) => {
  const trimedZipCode = zipCode?.trim();
  let isValid = false;
  let error = "Please input your ZIP code";
  const reg = /^[0-9]{5}(?:-[0-9]{4})?$/;
  isValid = reg.test(trimedZipCode);
  if (!isValid) {
    error = "Please input correct ZIP code";
  }

  return {
    isValid,
    error,
  };
};

const getIsValid = (validation, isBillingTheSameAsShipping) => {
  const isPaymentPartValid = validation.cardNumber.isValid && validation.expiryDate.isValid && validation.cvv.isValid
  if (isBillingTheSameAsShipping) {
    return isPaymentPartValid
  }

  return (
    isPaymentPartValid &&
    validation.firstName &&
    validation.lastName &&
    validation.address &&
    validation.city &&
    validation.country &&
    validation.zipCode.isValid &&
    validation.state
  );
};

const handleUserCartAddressLoaded = (state, action) => {
  if (action?.payload?.user?.activeCart) {
    const cart = action.payload.user.activeCart;

    state.firstName = cart.billingFirstName;
    state.lastName = cart.billingLastName;

    state.address = cart.billingAddress;
    state.address2 = cart.billingAddress2;
    state.city = cart.billingCity;
    state.state = cart.billingState;
    state.zipCode = cart.billingZipCode;
    state.countryId = 1;

    state.validation.firstName = state.address !== "";
    state.validation.lastName = state.lastName !== "";

    state.validation.address = state.address !== "";
    state.validation.city = state.city !== "";
    state.validation.state = state.state !== "";
    state.validation.zipCode = state.zipCode !== "";

    state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
  }
};

const billingSlice = createSlice({
  name: "billing",
  initialState: {
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    countryId: 1,
    countryOption: {
      value: 1,
      label: "United States of America",
    },
    zipCode: "",
    state: "",
    stateOption: undefined,
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    UIState: {
      isLoading: false,
    },
    isBillingTheSameAsShipping: true,
    isValid: false,
    validation: {
      cardNumber: {
        isValid: false,
        error: "Please input Card Number",
      },
      expiryDate: {
        isValid: false,
        error: "Please input Expiry Date",
      },
      cvv: {
        isValid: false,
        error: "Please input CVV",
      },
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      country: true,
      zipCode: {
        isValid: false,
        error: "Please input your ZIP code",
      },
      state: false,
    },
    orderId: null,
    token: null,
  },
  reducers: {
    setCardNumber: (state, action) => {
      state.cardNumber = action.payload;
      state.validation.cardNumber = isValidCardNumber(action.payload);
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setExpiryDate: (state, action) => {
      state.expiryDate = action.payload;
      state.validation.expiryDate = isValidExpiryDate(action.payload);
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setCvv: (state, action) => {
      state.cvv = action.payload;
      state.validation.cvv = isValidCvv(action.payload);
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
      state.validation.firstName = action.payload !== "";
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
      state.validation.lastName = action.payload !== "";
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setAddress: (state, action) => {
      state.address = action.payload;
      state.validation.address = action.payload !== "";
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setAddress2: (state, action) => {
      state.address2 = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
      state.validation.city = action.payload !== "";
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setCountry: (state, action) => {
      state.countryOption = action.payload;
      state.countryId = action.payload.value;
      state.validation.country = action.payload !== "";
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
      state.validation.zipCode = isZipCodeValid(action.payload);
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setState: (state, action) => {
      state.stateOption = action.payload;
      state.state = action.payload.label;
      state.validation.state = action.payload !== "";
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping);
    },
    setIsBillingTheSameAsShipping: (state, action) => {
      state.isBillingTheSameAsShipping = action.payload;
      state.isValid = getIsValid(state.validation, state.isBillingTheSameAsShipping)
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: {
    [takePayment.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [takePayment.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [takePayment.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },
    [addSubscriptionToCart.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [addSubscriptionToCart.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [addSubscriptionToCart.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },
    [removeSubscriptionFromCart.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [removeSubscriptionFromCart.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [removeSubscriptionFromCart.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },
    [customerAccountLogout.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },
    [customerAccountLogout.fulfilled]: (state, action) => {
      state.firstName = ''
      state.lastName = ''
      state.address = ''
      state.address2 = ''
      state.city = ''
      state.state = ''
      state.stateOption = undefined
      state.zipCode = ''
      state.UIState.isLoading = false
      state.cardNumber = ''
      state.expiryDate = ''
      state.cvv = ''
    },
    [customerAccountLogout.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },
    "login/customerAccountLogin/fulfilled": handleUserCartAddressLoaded,
    "login/customerAccountRefreshToken/fulfilled": handleUserCartAddressLoaded,
  },
});

export const actionCreators = billingSlice.actions;
export const selectBillingState = (state) => state.BillingPage;
export const selectOrderId = (state) => state.BillingPage.orderId;
export const selectors = {
  selectBillingState,
  selectOrderId,
};

export default billingSlice.reducer;
