import { createAsyncThunk } from '@reduxjs/toolkit'
import { actionCreators as shippingActionCreators } from '../ShippingPage/reducer';
import { actionCreators } from './reducer'

// eslint-disable-next-line no-mixed-operators
const uuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))

const authorizeGetToken = async ({ paymentPluginSettings, paymentApiGateway, cardInfo }) => {
  const response = await fetch(paymentApiGateway.authorize, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      securePaymentContainerRequest: {
        merchantAuthentication: {
          name: paymentPluginSettings.apiLoginId,
          clientKey: paymentPluginSettings.clientKey
        },
        data: {
          type: 'TOKEN',
          id: uuid(),
          token: cardInfo
        }
      }
    })
  })
  const { messages, opaqueData } = await response.json()
  const isSuccessful = messages.resultCode === 'Ok'
  return {
    paymentToken: isSuccessful ? opaqueData.dataValue : null,
    errors: isSuccessful ? null : messages.message
  }
}

export const takePayment = createAsyncThunk('cart/takePayment', async ({ paymentPluginSettings, paymentApiGateway }, thunkAPI) => {
  let token
  const state = thunkAPI.getState()
  const cardInfo = {
    cardNumber: state.BillingPage.cardNumber.replace(/\s/g, ''),
    expirationDate: state.BillingPage.expiryDate.replace('/', ''),
    cardCode: state.BillingPage.cvv
  }
  switch (paymentPluginSettings.type) {
    case 'Authorize': {
      const { paymentToken, errors } = await authorizeGetToken({
        paymentPluginSettings,
        paymentApiGateway,
        cardInfo
      })
      if (errors) {
        throw new Error(errors.map(item => item.text).join('\n'))
      }
      token = paymentToken
      break
    }
    default: {
      throw new Error('Payment plugin not found')
    }
  }
  const customerInfo = state.BillingPage.isBillingTheSameAsShipping ? state.ShippingPage : state.BillingPage
  const variables = {
    cartId: state.HomePage.cart.id,
    paymentInfo: {
      customerInfo: {
        firstName: customerInfo.firstName?.trim(),
        lastName: customerInfo.lastName?.trim(),
        email: state.ShippingPage.email?.trim()
      },
      billingAddress: {
        countryId: customerInfo.countryId,
        zipCode: customerInfo.zipCode?.trim(),
        state: customerInfo.state?.trim(),
        city: customerInfo.city?.trim(),
        address: customerInfo.address?.trim(),
        address2: customerInfo.address2?.trim()
      },
      cardInfo: {
        bin: cardInfo.cardNumber.substr(0, 6),
        cardNumber: cardInfo.cardNumber.substr(-4),
        expDate: state.BillingPage.expiryDate
      },
      token
    }
  }

  const headers = { 'Content-Type': 'application/json' }
  const authorizationToken = sessionStorage.getItem('accessToken')
  if (authorizationToken) {
    headers.authorization = `Bearer ${authorizationToken}`
  }
  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `
          mutation TakePaymentV2($cartId: ID!, $paymentInfo: PaymentInfoInput!) {
            takePaymentV2(cartId: $cartId, paymentInfo: $paymentInfo) {
              payment {
                orderId
                token
              }
              orderData {
                orderId
                
                customerId
                customerEmail
                customerEmailValid
                customerFirstName
                customerLastName

                shippingFirstName
                shippingLastName
                shippingAddress
                shippingCity
                shippingState
                shippingZipCode
                shippingCountry

                billingFirstName
                billingLastName
                billingAddress
                billingCity
                billingState
                billingZipCode
                billingCountry

                sum
                processing
                shipping
                total
                items {
                  displayName
                  price
                  finalPrice
                  quantity
                  shipping
                }
              }
            }
          }
        `,
      variables
    })
  })

  const {
    data: { takePaymentV2 },
    errors
  } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!takePaymentV2) {
    throw new Error('Can not take payment!')
  }

  thunkAPI.dispatch(actionCreators.setOrderId(takePaymentV2.payment.orderId))
  thunkAPI.dispatch(actionCreators.setToken(takePaymentV2.payment.token))
  thunkAPI.dispatch(shippingActionCreators.setEmailValid(takePaymentV2.orderData.customerEmailValid));
  return {
    order: takePaymentV2.orderData,
    isSaved: true
  }
})

export const addSubscriptionToCart = createAsyncThunk('cart/addSubscriptionToCart', async ({ landingId }, thunkAPI) => {
  const {
    HomePage: {
      cart: { id: cartId, cookie },
      subscriptionProduct
    }
  } = thunkAPI.getState()
  const headers = { 'Content-Type': 'application/json' }
  const authorizationToken = sessionStorage.getItem('accessToken')
  if (authorizationToken) {
    headers.authorization = `Bearer ${authorizationToken}`
  }

  if (!subscriptionProduct) {
    throw new Error('Unknown subscription product!')
  }

  const subscriptionPrice = subscriptionProduct.prices[0]

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `
        mutation AddProductToCartV2($landingInfo: LandingInfoInput! ,$cartProductData: CartProductV2Input!) {
          addProductToCartV2(landingInfo: $landingInfo, cartProductData: $cartProductData) {
            id
            cookie
            
            customerId
            customerEmail
            customerFirstName
            customerLastName

            shippingFirstName
            shippingLastName
            shippingAddress
            shippingCity
            shippingState
            shippingZipCode
            shippingCountry

            billingFirstName
            billingLastName
            billingAddress
            billingCity
            billingState
            billingZipCode
            billingCountry

            items {
              id
              productId
              productPriceId
              displayName
              sku
              quantity
              price
              discount
              finalPrice
              sum
              shipping
              processing
              total
              attributes {
                attribute
                value
              }
            }
            sum
            shipping
            processing
            total
            comment
          }
        }
      `,
      variables: {
        landingInfo: {
          landingId,
          route: '/billing',
          cartId,
          cookie
        },
        cartProductData: {
          productId: subscriptionProduct.id,
          productPriceId: subscriptionPrice.id,
          quantity: 1,
          price: subscriptionPrice.price,
          discount: 0
        }
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error('Backend response does not contain answer body!')
  }

  const { addProductToCartV2 } = data
  if (!addProductToCartV2) {
    throw new Error('Can not add subscription to the cart!')
  }

  return {
    cart: addProductToCartV2,
    isSaved: true
  }
})

export const removeSubscriptionFromCart = createAsyncThunk('cart/removeSubscriptionFromCart', async (_args, thunkAPI) => {
  const {
    HomePage: {
      cart: { id: cartId, cookie },
      subscriptionProduct
    }
  } = thunkAPI.getState()
  const headers = { 'Content-Type': 'application/json' }
  const authorizationToken = sessionStorage.getItem('accessToken')
  if (authorizationToken) {
    headers.authorization = `Bearer ${authorizationToken}`
  }

  if (!subscriptionProduct) {
    throw new Error('Unknown subscription product!')
  }

  const subscriptionPrice = subscriptionProduct.prices[0]

  if (!subscriptionPrice) {
    throw new Error('Subscription product should have a price!')
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `
        mutation RemoveProductFromCartV2($cartId: ID!, $cookie: String!, $productId: ID!, $productPriceId: ID!) {
          removeProductFromCartV2(cartId: $cartId, cookie: $cookie, productId: $productId, productPriceId: $productPriceId) {
            id
            cookie
            
            customerId
            customerEmail
            customerFirstName
            customerLastName

            shippingFirstName
            shippingLastName
            shippingAddress
            shippingCity
            shippingState
            shippingZipCode
            shippingCountry

            billingFirstName
            billingLastName
            billingAddress
            billingCity
            billingState
            billingZipCode
            billingCountry

            items {
              id
              productId
              productPriceId
              displayName
              sku
              quantity
              price
              discount
              finalPrice
              sum
              shipping
              processing
              total
              attributes {
                attribute
                value
              }
            }
            sum
            shipping
            processing
            total
            comment
          }
        }
      `,
      variables: {
        cartId,
        cookie,

        productId: subscriptionProduct.id,
        productPriceId: subscriptionPrice.id
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error('Backend response does not contain answer body!')
  }

  const { removeProductFromCartV2 } = data
  if (!removeProductFromCartV2) {
    throw new Error('Can not remove subscription from the cart!')
  }

  return {
    cart: removeProductFromCartV2,
    isSaved: true
  }
})
