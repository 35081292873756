import { createSlice } from '@reduxjs/toolkit'
import { getProductMenuFromCollectionPrices } from '@merchx-v2/helpers'

const sizesOrder = ['SM', 'MD', 'LG', 'XL', '2XL']

const handleCartLoaded = (state, cart) => {
  if (cart) {
    state.cart.id = cart.id
    state.cart.cookie = cart.cookie
    state.cart.sum = cart.sum
    state.cart.shipping = cart.shipping
    state.cart.processing = cart.processing
    state.cart.total = cart.total
    state.cart.items = cart.items

    state.cart.totalWithoutProcessing = (parseFloat(cart.sum) + parseFloat(cart.shipping)).toFixed(2)
  }
}

const handleOrderLoaded = (state, order) => {
  if (order) {
    state.cart.orderId = order.orderId
    state.cart.sum = order.sum
    state.cart.shipping = order.shipping
    state.cart.processing = order.processing
    state.cart.total = order.total
    state.cart.items = order.items

    state.cart.totalWithoutProcessing = (parseFloat(order.sum) + parseFloat(order.shipping)).toFixed(2)
  }
}

export const handleActiveCartLoaded = (state, action) => {
  handleCartLoaded(state, action?.payload?.user?.activeCart)
}

const emptyCart = {
  id: undefined,
  orderId: undefined,
  cookie: undefined,
  sum: '0.00',
  shipping: '0.00',
  processing: '0.00',
  total: '0.00',
  items: [],

  totalWithoutProcessing: '0.00'
}

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cart: { ...emptyCart },
    prices: [],
    subscriptionProduct: undefined,
    menu: {
      items: [],
      selectedCollection: undefined
    }
  },
  reducers: {
    setPrices: (state, action) => {
      const { prices, subscriptionProduct } = action.payload

      state.prices = prices

      state.subscriptionProduct = subscriptionProduct
      state.menu.items = getProductMenuFromCollectionPrices(prices)
    },
    resetMenuFilters: (state, action) => {
      state.menu.selectedCollection = undefined
      state.menu.selectedSubcollection = undefined
    },
    setMenuCollection: (state, action) => {
      state.menu.selectedCollection = action.payload
      state.menu.selectedSubcollection = undefined
    },
    clearCart: (state, action) => {
      state.cart = { ...emptyCart }
    },
    setSearchValue: (state, action) => {
      state.menu.selectedCollection = undefined
    }
  },
  extraReducers: {
    'login/customerAccountRegister/fulfilled': handleActiveCartLoaded,
    'login/customerAccountLogin/fulfilled': handleActiveCartLoaded,
    'login/customerAccountRefreshToken/fulfilled': handleActiveCartLoaded,
    'cart/addProduct/fulfilled': (state, action) => handleCartLoaded(state, action.payload?.cart),
    'cart/addSubscriptionToCart/fulfilled': (state, action) => handleCartLoaded(state, action.payload?.cart),
    'cart/removeSubscriptionFromCart/fulfilled': (state, action) => handleCartLoaded(state, action.payload?.cart),
    'cart/addUpsellToOrder/fulfilled': (state, action) => handleOrderLoaded(state, action.payload?.order),
    'cart/takePayment/fulfilled': (state, action) => handleOrderLoaded(state, action.payload?.order),
    'login/customerAccountLogout/fulfilled': (state, action) => {
      state.cart = { ...emptyCart }
    }
  }
})

export const actionCreators = cartSlice.actions

export const { setMenuCollection, setSearchValue } = actionCreators

export const selectCartId = state => state.HomePage.cart.id
export const selectMenu = state => state.HomePage.menu
export const selectPrices = state => state.HomePage.prices
export const selectSubscriptionProduct = state => state.HomePage.subscriptionProduct
export const selectSearchValue = state => state.HomePage.searchValue

export const selectProducts = state => {
  const { selectedCollection, items } = state.HomePage.menu

  return items
    .reduce((accum, collection) => {
      if (collection.productPrices.length) {
        if (!selectedCollection || collection.name === selectedCollection)
          for (const productPrice of collection.productPrices) {
            const existPrice = accum.find(item => item.productId === productPrice.productId)
            if (!existPrice) {
              const priceObj = {
                ...productPrice,
                collectionName: collection.name
              }
              accum.push(priceObj)
            } else {
              const sizeA = existPrice.attributes.find(item => item.attribute === 'size') || { value: 'SM' }
              const sizeB = productPrice.attributes.find(item => item.attribute === 'size') || { value: 'SM' }

              if (sizesOrder.indexOf(sizeA.value) > sizesOrder.indexOf(sizeB.value)) {
                const index = accum.map(item => item.productId).indexOf(productPrice.productId)
                accum[index] = productPrice
              }
            }
          }
      }

      return accum
    }, [])
    .sort((a, b) => {
      const dateA = new Date(a.updatedAt),
        dateB = new Date(b.updatedAt)
      return dateB - dateA
    })
}

export const selectHomeState = state => state.HomePage
export const selectMemberEconomyDetails = state => {
  let result = {
    totalSaved: '0.00',
    retailPrice: '0.00',
    memberPrice: '0.00',
    subscriptionPrice: '0.00'
  }

  if (state.HomePage.subscriptionProduct?.prices?.length) {
    result.subscriptionPrice = (state.HomePage.subscriptionProduct.prices[0].memberPrice / 100).toFixed(2)
  }

  if (state.HomePage?.cart?.items?.length) {
    const { productPriceId } = state.HomePage.cart.items[0]
    const productPrice = state.HomePage?.prices.find(item => item.id + '' === productPriceId)
    if (productPrice) {
      result.retailPrice = ((productPrice.price - productPrice.discount) / 100).toFixed(2)
      result.memberPrice = (productPrice.memberPrice / 100).toFixed(2)
      result.totalSaved = ((productPrice.price - productPrice.discount - productPrice.memberPrice) / 100).toFixed(2)
    }
  }

  return result
}

export const selectIsCartContainsSubscription = state => {
  let result = false

  if (state.HomePage.cart.items?.length) {
    const subscriptionProduct = state.HomePage.subscriptionProduct
    const subscriptionPrice = state.HomePage.subscriptionProduct?.prices[0]
    const subscriptionItemInCart = state.HomePage.cart.items.find(item => item.productId === subscriptionProduct?.id + '' && item.productPriceId === subscriptionPrice?.id + '')
    result = !!subscriptionItemInCart
  }

  return result
}

export const selectors = {
  selectHomeState,
  selectMenu,
  selectMemberEconomyDetails,
  selectIsCartContainsSubscription,
  selectPrices,
  selectSubscriptionProduct,
  selectProducts
}

export default cartSlice.reducer
