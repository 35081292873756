import { createAsyncThunk } from "@reduxjs/toolkit";
import { actionCreators } from './reducer'

export const addShippingInfoToCart = createAsyncThunk("cart/addShippingInfo", async (args, thunkAPI) => {
  const state = thunkAPI.getState();

  const variables = {
    cartId: state.HomePage.cart.id,
    cookie: state.HomePage.cart.cookie,
    route: "/shipping",
    shippingInfo: {
      customerInfo: {
        firstName: state.ShippingPage.firstName?.trim(),
        lastName: state.ShippingPage.lastName?.trim(),
        email: state.ShippingPage.email?.trim(),
        phone: state.ShippingPage.phone?.trim(),
      },
      shippingAddress: {
        countryId: state.ShippingPage.countryId,
        zipCode: state.ShippingPage.zipCode?.trim(),
        state: state.ShippingPage.state?.trim(),
        city: state.ShippingPage.city?.trim(),
        address: state.ShippingPage.address?.trim(),
        address2: state.ShippingPage.address2?.trim(),
      },
    },
  };

  const headers = { "Content-Type": "application/json" };
  const authorizationToken = sessionStorage.getItem("accessToken");
  if (authorizationToken) {
    headers.authorization = `Bearer ${authorizationToken}`;
  }

  const response = await fetch(
    `${process.env.BACKEND_API}/graphql`,
    {
      method: "POST",
      headers,
      body: JSON.stringify({
        query: `
      mutation AddShippingInfoToCartV2($cartId: ID!, $cookie: String!, $route: String, $shippingInfo: ShippingInfoInput!) {
        addShippingInfoToCartV2(cartId: $cartId, cookie: $cookie, route: $route, shippingInfo: $shippingInfo) {
          id
          cookie
          customerId
          customerEmail
          customerFirstName
          customerLastName
          shippingFirstName
          shippingLastName
          shippingAddress
          shippingAddress2
          shippingCity
          shippingState
          shippingZipCode
          shippingCountry
          billingFirstName
          billingLastName
          billingAddress
          billingAddress2
          billingCity
          billingState
          billingZipCode
          billingCountry
          items {
            id
            productId
            displayName
            sku
            quantity
            price
            discount
            finalPrice
            sum
            saved
            shipping
            processing
            total
          }
          sum
          shipping
          processing
          total
          comment
        }
      }
    `,
        variables,
      }),
    }
  );

  const { data, errors } = await response.json();

  if (errors) {
    throw new Error(errors.map((item) => item.message).join("\n"));
  }

  const { addShippingInfoToCartV2 } = data;
  if (!addShippingInfoToCartV2) {
    throw new Error("Can not add shipping info!");
  }
  return true;
})

export const updateCartCustomerEmail = createAsyncThunk('cart/updateCartCustomerEmail', async ({ email }, thunkAPI) => {
  const state = thunkAPI.getState()

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        mutation UpdateCartCustomerEmail($cartId: ID!, $cookie: String!, $email: String!) {
          updateCartCustomerEmail(cartId: $cartId, cookie: $cookie, email: $email) {
            customerEmail
            customerEmailValid
          }
        }
      `,
      variables: {
        cartId: state.HomePage.cart.id,
        cookie: state.HomePage.cart.cookie,
        email
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { updateCartCustomerEmail } = data
  if (!updateCartCustomerEmail) {
    throw new Error('Can not update customer email!')
  }

  thunkAPI.dispatch(actionCreators.setEmail(updateCartCustomerEmail.customerEmail))
  thunkAPI.dispatch(actionCreators.setEmailValid(updateCartCustomerEmail.customerEmailValid))

  return true
})

