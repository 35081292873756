import { createSlice } from "@reduxjs/toolkit";
import { addUpsellToOrder } from "./actions";

const upgradeSlice = createSlice({
  name: "upgrade",
  initialState: {
    selectedProductPrice: {},
    UIState: {
      error: undefined,
      isLoading: false,
    },
  },
  reducers: {
    setPrices: (state, action) => {
      const { prices } = action.payload
      state.selectedProductPrice = prices[0]
    },
  },
  extraReducers: {
    [addUpsellToOrder.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [addUpsellToOrder.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [addUpsellToOrder.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },
  },
});

export const actionCreators = upgradeSlice.actions;
export const selectUpgradeState = (state) => state.UpgradePage;
export const selectors = {
  selectUpgradeState,
};

export default upgradeSlice.reducer;
