import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            keywords
            siteUrl
            landing {
              BACKEND_API
              LANDING_ID
              WORKSPACE_ID
              CAMPAIGN {
                id
                name
              }
              PAYMENT_PLUGIN
              PAYMENT_SETTINGS {
                id
                workspaceId
                name
                apiGateway
                apiLoginId
                clientKey
              }
              SHIPPING_COST
              APPLY_SHIPPING_ONCE
              PROCESSING_COST
              APPLY_PROCESSING_ONCE
              ALLOW_MULTIPLE_PAYMENTS
              COUNTRIES {
                id
                name
                regions {
                  id
                  countryId
                  name
                  iso2
                }
              }
            }
            shared {
              HAS_STICKERS_PAGE
              FACEBOOK_LINK
              SUPPORT_EMAIL
              HAS_UPSELLS_PAGE
              HAS_UPGRADE_PAGE
              SUPPORT_ADDRESS
              SUPPORT_PHONE
              FACEBOOK_PIXEL_ID
              GOOGLE_SHOPPING_SNIPPET
              GOOGLE_GLOBAL_TAG
              GOOGLE_OPTIMIZE_SNIPPET
              HOTJAR_SITE_ID
              GOOGLE_GLOBAL_TAG_NOSCRIPT
              ANTI_FLICKER_SNIPPET
              INSTAGRAM_LINK
              TWITTER_LINK
              BLOG_LINK
              CHAT_LINK
              NEWS_LINK
              HOME_TITLE
              QUICK_SEARCH_KEYS
              LOGO_IMAGE {
                publicUrl
              }
              SUBSCRIPTION_PRODUCT {
                campaignId
                customShippingPrice
                description
                displayName
                hasCustomShipping
                id
                name
                createdAt
                productType
                workspaceId
                prices {
                  addedAt
                  attributes {
                    attribute
                    createdAt
                    id
                    ownerId
                    ownerType
                    productPriceId
                    value
                  }
                  createdAt
                  description
                  discount
                  displayName
                  finalPrice
                  id
                  landingId
                  memberPrice
                  name
                  ownerId
                  ownerType
                  price
                  productDisplayName
                  productId
                  productType
                  productTypeV2
                  sku
                }
              }
            }
          }
        }
      }
    `
  );

  return site.siteMetadata;
};
