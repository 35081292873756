import { createSlice } from "@reduxjs/toolkit";
import { requestSupportV2 } from "./actions";

const isValidEmail = (email) => {
  const trimedEmail = email?.trim();
  let isValid = false;
  let error = "Please input your email";
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  isValid = reg.test(trimedEmail);
  if (!isValid) {
    error = "Please input correct email";
  }
  return {
    isValid,
    error,
  };
};

const getIsValid = (validation) => {
  return validation.email.isValid && validation.subject && validation.message;
};

const supportSlice = createSlice({
  name: "support",
  initialState: {
    firstName: "",
    lastName: "",
    email: "",
    orderNumber: "",
    subject: "",
    message: "",
    UIState: {
      error: undefined,
      isLoading: false,
    },
    isValid: false,
    validation: {
      firstName: false,
      lastName: false,
      email: {
        isValid: false,
        error: "Please input your email",
      },
      orderNumber: false,
      subject: false,
      message: false,
    },
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
      state.validation.firstName = action.payload !== "";
      state.isValid = getIsValid(state.validation);
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
      state.validation.lastName = action.payload !== "";
      state.isValid = getIsValid(state.validation);
    },
    setEmail: (state, action) => {
      state.email = action.payload;
      state.validation.email = isValidEmail(action.payload);
      state.isValid = getIsValid(state.validation);
    },
    setOrderNumber: (state, action) => {
      state.orderNumber = action.payload;
      state.validation.orderNumber = action.payload !== "";
      state.isValid = getIsValid(state.validation);
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
      state.validation.subject = action.payload !== "";
      state.isValid = getIsValid(state.validation);
    },
    setMessage: (state, action) => {
      state.message = action.payload;
      state.validation.message = action.payload !== "";
      state.isValid = getIsValid(state.validation);
    },
  },
  extraReducers: {
    [requestSupportV2.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [requestSupportV2.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [requestSupportV2.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },
  },
});

export const actionCreators = supportSlice.actions;
export const selectContactsState = (state) => state.Support;
export const selectors = {
  selectContactsState,
};

export default supportSlice.reducer;
