import { configureStore } from "@reduxjs/toolkit";

import billingReducer from "./BillingPage/reducer";
import homeReducer from "./HomePage/reducer";
import loginReducer from "./LoginPage/reducer";
import productViewReducer from "./ProductPage/reducer";
import shippingReducer from "./ShippingPage/reducer";
import stickerReducer from "./StickerPage/reducer";
import supportReducer from "./Support/reducer";
import thankyouReducer from "./ThankyouPage/reducer";
import upgradeReducer from "./UpgradePage/reducer";
import upsellReducer from "./UpsellPage/reducer";

export default configureStore({
  reducer: {
    BillingPage: billingReducer,
    HomePage: homeReducer,
    LoginPage: loginReducer,
    ProductPage: productViewReducer,
    ShippingPage: shippingReducer,
    StickerPage: stickerReducer,
    Support: supportReducer,
    ThankyouPage: thankyouReducer,
    UpgradePage: upgradeReducer,
    UpsellPage: upsellReducer,
  },
});
