import { createAsyncThunk } from "@reduxjs/toolkit";
import * as merchxHelpers from "assets/merchxHelpers";

export const addUpsellToOrder = createAsyncThunk(
  "cart/addUpsellToOrder",
  async (args, thunkAPI) => {
    const {
      BillingPage: { orderId, token },
      HomePage: {
        cart: { items },
      },
      UpsellPage: { prices },
    } = thunkAPI.getState();

    const mainProduct = items.length && items[0];

    if (!mainProduct) {
      throw new Error("Can't find main product price!");
    }

    const upsellProductSizeAttribute = mainProduct.attributes.find(
      (item) => item.attribute.toLowerCase() === "size"
    );

    if (!upsellProductSizeAttribute) {
      throw new Error(`Can't defind the product size!`);
    }

    const upsellProductPrice = merchxHelpers.getProductPrice(prices, [
      upsellProductSizeAttribute,
    ]);

    const headers = { "Content-Type": "application/json" };
    const authorizationToken = sessionStorage.getItem("accessToken");
    if (authorizationToken) {
      headers.authorization = `Bearer ${authorizationToken}`;
    }

    const response = await fetch(
      `${process.env.BACKEND_API}/graphql`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `
        mutation AddUpsellToOrderV2($orderId: ID!, $token: String!, $productData: ProductDataV2Input!) {
          addUpsellToOrderV2(orderId: $orderId, token: $token, productData: $productData) {
            payment {
              orderId
              token
            }
            orderData {
              orderId
              customerEmail
              
              shippingFirstName
              shippingLastName
              shippingAddress
              shippingCity
              shippingState
              shippingZipCode
              shippingCountry
              
              billingFirstName
              billingLastName
              billingAddress
              billingCity
              billingState
              billingZipCode
              billingCountry
              
              items {
                productId
                productPriceId
                displayName
                sku
                quantity
                price
                discount
                finalPrice
                sum
                shipping
                processing
                total
                attributes {
                  attribute
                  value
                }
              }

              sum
              shipping
              processing
              total
              totalSaved
            }
          }
        }
      `,
          variables: {
            orderId,
            token,
            productData: {
              productId: upsellProductPrice.productId,
              productPriceId: upsellProductPrice.id,
            },
          },
        }),
      }
    );

    const { data, errors } = await response.json();
    if (errors) {
      throw new Error(errors.map((item) => item.message).join("\n"));
    }

    const { addUpsellToOrderV2 } = data;
    if (!addUpsellToOrderV2) {
      throw new Error("Can not add product to order!");
    }

    return {
      order: addUpsellToOrderV2.orderData,
      isSaved: true,
    };
  }
);
