import { createSlice } from '@reduxjs/toolkit'
import { addShippingInfoToCart, updateCartCustomerEmail } from './actions'

const isValidEmail = email => {
  const trimedEmail = email?.trim()
  let isValid = false
  let error = 'Please input your email'
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  isValid = reg.test(trimedEmail)
  if (!isValid) {
    error = 'Please input correct email'
  }
  return {
    isValid,
    error
  }
}

const isPhoneValid = phone => {
  const trimedPhone = phone?.trim()
  let isValid = false
  let error = 'Please input your phone number'
  isValid = !trimedPhone.match(/[^0-9+]/) && trimedPhone.length >= 10 && trimedPhone.length <= 15
  if (!isValid) {
    error = 'Please input correct phone number'
  }

  return {
    isValid,
    error
  }
}

const isZipCodeValid = zipCode => {
  const trimedZipCode = zipCode?.trim()
  let isValid = false
  let error = 'Please input your ZIP code'
  const reg = /^[0-9]{5}(?:-[0-9]{4})?$/
  isValid = reg.test(trimedZipCode)
  if (!isValid) {
    error = 'Please input correct ZIP code'
  }

  return {
    isValid,
    error
  }
}

const getIsValid = validation => {
  return (
    validation.firstName &&
    validation.lastName &&
    validation.address &&
    validation.city &&
    validation.country &&
    validation.zipCode.isValid &&
    validation.state &&
    validation.email.isValid &&
    validation.phone.isValid
  )
}

const handleUserCartAddressLoaded = (state, action) => {
  if (action?.payload?.user?.activeCart) {
    const cart = action.payload.user.activeCart

    state.firstName = cart.shippingFirstName
    state.lastName = cart.shippingLastName

    state.address = cart.shippingAddress
    state.address2 = cart.shippingAddress2
    state.city = cart.shippingCity
    state.state = cart.shippingState
    state.zipCode = cart.shippingZipCode
    state.countryId = 1

    state.validation.firstName = state.address !== ''
    state.validation.lastName = state.lastName !== ''

    state.validation.address = state.address !== ''
    state.validation.city = state.city !== ''
    state.validation.state = state.state !== ''
    state.validation.zipCode = state.zipCode !== ''

    state.isValid = getIsValid(state.validation)
  }
}

const shippingSlice = createSlice({
  name: 'shipping',
  initialState: {
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    countryId: 1,
    countryOption: {
      value: 1,
      label: 'United States of America'
    },
    zipCode: '',
    state: '',
    stateOption: undefined,
    email: '',
    phone: '',
    UIState: {
      isLoading: false
    },
    isValid: false,
    validation: {
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      country: true,
      zipCode: {
        isValid: false,
        error: 'Please input your ZIP code'
      },
      state: false,
      email: {
        isValid: false,
        error: 'Please input your email'
      },
      phone: {
        isValid: false,
        error: 'Please input your phone'
      }
    }
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload
      state.validation.firstName = action.payload !== ''
      state.isValid = getIsValid(state.validation)
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
      state.validation.lastName = action.payload !== ''
      state.isValid = getIsValid(state.validation)
    },
    setAddress: (state, action) => {
      state.address = action.payload
      state.validation.address = action.payload !== ''
      state.isValid = getIsValid(state.validation)
    },
    setAddress2: (state, action) => {
      state.address2 = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
      state.validation.city = action.payload !== ''
      state.isValid = getIsValid(state.validation)
    },
    setCountry: (state, action) => {
      state.countryOption = action.payload
      state.countryId = action.payload.value
      state.validation.country = action.payload !== ''
      state.isValid = getIsValid(state.validation)
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload
      state.validation.zipCode = isZipCodeValid(action.payload)
      state.isValid = getIsValid(state.validation)
    },
    setState: (state, action) => {
      state.stateOption = action.payload
      state.state = action.payload.label
      state.validation.state = action.payload !== '' && action.payload.label !== ''
      state.isValid = getIsValid(state.validation)
    },
    setEmail: (state, action) => {
      state.email = action.payload
      state.validation.email = isValidEmail(action.payload)
      state.isValid = getIsValid(state.validation)
    },
    setPhone: (state, action) => {
      state.phone = action.payload
      state.validation.phone = isPhoneValid(action.payload)
      state.isValid = getIsValid(state.validation)
    },
    setEmailValid: (state, action) => {
      state.emailValid = action.payload;
    },
  },
  extraReducers: {
    [addShippingInfoToCart.pending]: (state, action) => {
      state.UIState.isLoading = true
      delete state.UIState.error
    },

    [addShippingInfoToCart.fulfilled]: (state, action) => {
      state.UIState.isLoading = false
    },

    [addShippingInfoToCart.rejected]: (state, action) => {
      state.UIState.isLoading = false
      state.UIState.error = action.error.message
    },

    [updateCartCustomerEmail.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [updateCartCustomerEmail.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [updateCartCustomerEmail.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },

    'login/customerAccountLogin/fulfilled': handleUserCartAddressLoaded,
    'login/customerAccountRefreshToken/fulfilled': handleUserCartAddressLoaded
  }
})

export const actionCreators = shippingSlice.actions
export const selectShippingState = state => state.ShippingPage

export const selectors = {
  selectShippingState
}

export default shippingSlice.reducer
