import { createAsyncThunk } from '@reduxjs/toolkit'

const saveRefreshToken = (refreshToken, isRememberMe) => {
  if (isRememberMe === true) {
    localStorage.setItem('refreshToken', refreshToken)
  } else {
    sessionStorage.setItem('refreshToken', refreshToken)
  }
}

const saveAccessToken = accessToken => {
  sessionStorage.setItem('accessToken', accessToken)
}

const saveTokenExpiresIn = expiresIn => {
  sessionStorage.setItem('expiresIn', expiresIn)
}

export const isPasswordValid = password => {
  const trimmedPassword = password?.trim()
  let isValid = false
  let error = 'Please input password'
  const reg = /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{6,})$/
  isValid = reg.test(trimmedPassword)
  if (!isValid) {
    error = 'Please input correct Password'
  }

  return {
    isValid,
    error
  }
}

export const isEmailValid = email => {
  const trimedEmail = email?.trim()
  let isValid = false
  let error = 'Please input your email'
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  isValid = reg.test(trimedEmail)
  if (!isValid) {
    error = 'Please input correct email'
  }

  return {
    isValid,
    error
  }
}

export const isZipCodeValid = zipCode => {
  const trimedZipCode = zipCode?.trim()
  let isValid = false
  let error = 'Please input your ZIP code'
  const reg = /^[0-9]{5}(?:-[0-9]{4})?$/
  isValid = reg.test(trimedZipCode)
  if (!isValid) {
    error = 'Please input correct ZIP code'
  }

  return {
    isValid,
    error
  }
}

// eslint-disable-next-line no-mixed-operators
const uuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))

const authorizeGetToken = async ({ paymentPluginSettings, paymentApiGateway, cardInfo }) => {
  const response = await fetch(paymentApiGateway.authorize, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      securePaymentContainerRequest: {
        merchantAuthentication: {
          name: paymentPluginSettings.apiLoginId,
          clientKey: paymentPluginSettings.clientKey
        },
        data: {
          type: 'TOKEN',
          id: uuid(),
          token: cardInfo
        }
      }
    })
  })
  const { messages, opaqueData } = await response.json()
  const isSuccessful = messages.resultCode === 'Ok'
  return {
    paymentToken: isSuccessful ? opaqueData.dataValue : null,
    errors: isSuccessful ? null : messages.message
  }
}

export const customerAccountRegister = createAsyncThunk('login/customerAccountRegister', async (args, thunkAPI) => {
  const {
    HomePage: {
      cart: { id: cartId, cookie: cartCookie }
    }
  } = thunkAPI.getState()

  const variables = {
    campaignId: args.campaignId,
    landingId: args.landingId,
    email: args.email?.trim().toLowerCase(),
    password: args.password?.trim(),
    firstName: args.firstName?.trim() || '',
    lastName: args.lastName?.trim() || '',
    cartId,
    cartCookie
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        mutation CustomerAccountRegister($campaignId: ID!, $landingId: ID!, $email: String!, $password: String!, $firstName: String, $lastName: String, $cartId: ID, $cartCookie: String) {
          customerAccountRegister(campaignId: $campaignId, landingId: $landingId, email: $email, password: $password, firstName: $firstName, lastName: $lastName, cartId: $cartId, cartCookie: $cartCookie) {
            accessToken
            refreshToken
            expiresIn
            user {
              firstName
              lastName
              email
              phone
              shippingAddress
              shippingAddress2
              shippingCity
              shippingState
              shippingZipCode
              shippingCountry
              membershipInfo {
                name
                status
                activeFrom
                paidTill
                nextPaymentDate
                recentPayments {
                  id
                  status
                  createdAt
                  total
                }
              }
              activeCart {
                id
                cookie
                
                customerId
                customerEmail
                customerFirstName
                customerLastName

                shippingFirstName
                shippingLastName
                shippingAddress
                shippingCity
                shippingState
                shippingZipCode
                shippingCountry

                billingFirstName
                billingLastName
                billingAddress
                billingCity
                billingState
                billingZipCode
                billingCountry

                items {
                  id
                  productId
                  productPriceId
                  displayName
                  sku
                  quantity
                  price
                  discount
                  finalPrice
                  sum
                  shipping
                  processing
                  total
                  attributes {
                    attribute
                    value
                  }
                }

                sum
                shipping
                processing
                total
                comment
              } 
            }
          }
        }
      `,
      variables
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { customerAccountRegister } = data
  if (!customerAccountRegister) {
    throw new Error('Can not register!')
  }

  saveRefreshToken(customerAccountRegister.refreshToken, false)
  saveAccessToken(customerAccountRegister.accessToken)

  return {
    ...customerAccountRegister,
    isRegistered: true
  }
})

export const customerAccountLogin = createAsyncThunk('login/customerAccountLogin', async (args, thunkAPI) => {
  const {
    HomePage: {
      cart: { id: cartId, cookie: cartCookie }
    }
  } = thunkAPI.getState()

  const variables = {
    campaignId: args.campaignId,
    email: args.email?.trim().toLowerCase(),
    password: args.password?.trim(),
    cartId,
    cartCookie
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        mutation CustomerAccountLogin($campaignId: ID!, $email: String!, $password: String!, $cartId: ID, $cartCookie: String) {
          customerAccountLogin(campaignId: $campaignId, email: $email, password: $password, cartId: $cartId, cartCookie: $cartCookie) {
            accessToken
            refreshToken
            expiresIn
            user {
              firstName
              lastName
              email
              phone
              shippingAddress
              shippingAddress2
              shippingCity
              shippingState
              shippingZipCode
              shippingCountry
              membershipInfo {
                name
                status
                activeFrom
                paidTill
                nextPaymentDate
                recentPayments {
                  id
                  status
                  createdAt
                  total
                }
              }
              activeCart {
                id
                cookie

                customerId
                customerEmail
                customerFirstName
                customerLastName

                shippingFirstName
                shippingLastName
                shippingAddress
                shippingCity
                shippingState
                shippingZipCode
                shippingCountry

                billingFirstName
                billingLastName
                billingAddress
                billingCity
                billingState
                billingZipCode
                billingCountry

                items {
                  id
                  productId
                  productPriceId
                  displayName
                  sku
                  quantity
                  price
                  discount
                  finalPrice
                  sum
                  shipping
                  processing
                  total
                  attributes {
                    attribute
                    value
                  }
                }
                
                sum
                shipping
                processing
                total
                comment
              } 
            }
          }
        }
      `,
      variables
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { customerAccountLogin } = data
  if (!customerAccountLogin) {
    throw new Error('Can not log in!')
  }

  saveRefreshToken(customerAccountLogin.refreshToken, args.isRememberMe || false)
  saveAccessToken(customerAccountLogin.accessToken)
  saveTokenExpiresIn(customerAccountLogin.expiresIn)

  return {
    ...customerAccountLogin,
    isAuthorized: true
  }
})

export const customerAccountRefreshToken = createAsyncThunk('login/customerAccountRefreshToken', async (args, thunkAPI) => {
  const state = thunkAPI.getState()
  let result = { isAuthorized: false }

  const refreshToken = localStorage.refreshToken || sessionStorage.refreshToken

  if (refreshToken) {
    const variables = {
      refreshToken
    }

    const isRememberMe = state.LoginPage.isRememberMe

    const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
            mutation CustomerAccountRefreshToken($refreshToken: String!) {
              customerAccountRefreshToken(refreshToken: $refreshToken) {
                accessToken
                refreshToken
                expiresIn
                user {
                  firstName
                  lastName
                  email
                  phone
                  shippingAddress
                  shippingAddress2
                  shippingCity
                  shippingState
                  shippingZipCode
                  shippingCountry
                  membershipInfo {
                    name
                    status
                    activeFrom
                    paidTill
                    nextPaymentDate
                    recentPayments {
                      id
                      status
                      createdAt
                      total
                    }
                  }
                  activeCart {
                    id
                    cookie
                    
                    customerId
                    customerEmail
                    customerFirstName
                    customerLastName

                    shippingFirstName
                    shippingLastName
                    shippingAddress
                    shippingCity
                    shippingState
                    shippingZipCode
                    shippingCountry

                    billingFirstName
                    billingLastName
                    billingAddress
                    billingCity
                    billingState
                    billingZipCode
                    billingCountry

                    items {
                      id
                      productId
                      productPriceId
                      displayName
                      sku
                      quantity
                      price
                      discount
                      finalPrice
                      sum
                      shipping
                      processing
                      total
                      attributes {
                        attribute
                        value
                      }
                    }
                    
                    sum
                    shipping
                    processing
                    total
                    comment
                  } 
                }
              }
            }
          `,
        variables
      })
    })

    const { data, errors } = await response.json()

    if (errors) {
      throw new Error(errors.map(item => item.message).join('\n'))
    }

    const { customerAccountRefreshToken } = data
    if (!customerAccountRefreshToken) {
      throw new Error('Can not log in!')
    }

    saveRefreshToken(customerAccountRefreshToken.refreshToken, isRememberMe)
    saveAccessToken(customerAccountRefreshToken.accessToken)
    saveTokenExpiresIn(customerAccountRefreshToken.expiresIn)

    result = {
      ...customerAccountRefreshToken,
      isAuthorized: true
    }
  }

  return result
})

export const customerAccountLogout = createAsyncThunk('login/customerAccountLogout', async (args, thunkAPI) => {
  const state = thunkAPI.getState()
  let result = { isLogout: false }

  const bearer = sessionStorage.getItem('accessToken')

  if (bearer) {
    const variables = {
      logoutFromAllDevices: state.LoginPage.logoutFromAllDevices
    }

    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('expiresIn')

    const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${bearer}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `
      mutation CustomerAccountLogout($logoutFromAllDevices: Boolean!) {
        customerAccountLogout(logoutFromAllDevices: $logoutFromAllDevices) 
      }
    `,
        variables
      })
    })

    const { data, errors } = await response.json()

    if (errors) {
      throw new Error(errors.map(item => item.message).join('\n'))
    }

    const { customerAccountLogout } = data
    if (!customerAccountLogout) {
      throw new Error('Can not log out!')
    }

    result = {
      ...customerAccountLogout,
      isLogout: true
    }
  }

  return result
})

export const updateCustomerAccount = createAsyncThunk('login/updateCustomerAccount', async (args, thunkAPI) => {
  const customerAccountData = {
    firstName: args.firstName?.trim(),
    lastName: args.lastName?.trim(),
    address: {
      countryId: 1,
      address: args.address,
      address2: args.address2,
      city: args.city,
      state: args.state,
      zipCode: args.zipCode
    }
  }

  const variables = {
    customerAccountData
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        mutation UpdateCustomerAccount($customerAccountData: CustomerAccountInput!) {
          updateCustomerAccount(customerAccountData: $customerAccountData) 
        }
      `,
      variables
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { updateCustomerAccount } = data
  if (!updateCustomerAccount) {
    throw new Error('Can not update customer account info!')
  }

  return { updateCustomerAccount }
})

export const validateCustomerEmail = createAsyncThunk('login/validateCustomerEmail', async ({ emailConfirmationToken }, thunkAPI) => {
  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        mutation ValidateCustomerEmail($token: String!) {
          validateCustomerEmail(token: $token) 
        }
      `,
      variables: {
        token: emailConfirmationToken
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error("Can't confirm customer email!")
  }

  const { validateCustomerEmail } = data
  if (!validateCustomerEmail) {
    throw new Error("Can't confirm customer email!")
  }

  return validateCustomerEmail
})

export const requestResetPassword = createAsyncThunk('login/requestResetPassword', async (args, thunkAPI) => {
  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        mutation CustomerAccountRequestResetPassword($landingId: ID!, $email: String!) {
          customerAccountRequestResetPassword(landingId: $landingId, email: $email) 
        }
      `,
      variables: {
        landingId: args.landingId,
        email: args.email?.trim() || ''
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error('Internal error.')
  }

  const { customerAccountRequestResetPassword } = data
  if (!customerAccountRequestResetPassword) {
    throw new Error('Internal error.')
  }

  return true
})

export const resetPassword = createAsyncThunk('login/resetPassword', async (args, thunkAPI) => {
  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        mutation CustomerAccountResetPassword($newPassword: String!, $token: String!) {
          customerAccountResetPassword(newPassword: $newPassword, token: $token) 
        }
      `,
      variables: {
        newPassword: args.password,
        token: args.token
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error("Can't reset password!")
  }

  const { customerAccountResetPassword } = data
  if (!customerAccountResetPassword) {
    throw new Error("Can't reset password!")
  }

  return true
})

export const changePassword = createAsyncThunk('login/changePassword', async (args, thunkAPI) => {
  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        mutation CustomerAccountChangePassword($oldPassword: String!, $newPassword: String!) {
          customerAccountChangePassword(oldPassword: $oldPassword, newPassword: $newPassword) 
        }
      `,
      variables: {
        oldPassword: args.oldPassword,
        newPassword: args.newPassword
      }
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error("Can't change password!")
  }

  const { customerAccountChangePassword } = data
  if (!customerAccountChangePassword) {
    throw new Error("Can't change password!")
  }

  return true
})

export const customerAccountOrdersPage = createAsyncThunk('login/customerAccountOrdersPage', async (args, thunkAPI) => {
  const variables = {
    page: 1,
    size: 10
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        query CustomerAccountOrdersPage($page: Int, $size: Int) {
          customerAccountOrdersPage(page: $page, size: $size) {
            items {
              orderId
              customerFirstName
              customerLastName
              shippingFirstName
              shippingLastName
              shippingAddress
              shippingAddress2
              shippingCity
              shippingState
              shippingZipCode
              shippingCountry
              items {
                displayName
                quantity
                price
                discount
                finalPrice
                sum
                saved
                shipping
                processing
                total
                imagePublicUrl
                attributes {
                  attribute
                  value
                }
              }
              totalSaved
              sum
              processing
              shipping
              total
              trackingNumber
              createdAt
            }
            total
          }
        }
      `,
      variables
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { customerAccountOrdersPage } = data
  if (!customerAccountOrdersPage) {
    throw new Error('Can not get orders!')
  }

  return {
    items: customerAccountOrdersPage.items,
    total: customerAccountOrdersPage.total
  }
})

export const customerAccountDeactivateSubscription = createAsyncThunk('login/customerAccountDeactivateSubscription', async (args, thunkAPI) => {
  const variables = {}
  const {
    LoginPage: { subscriptionDeclineReason }
  } = thunkAPI.getState()

  if (subscriptionDeclineReason) {
    variables.reason = subscriptionDeclineReason
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      variables,
      query: `
            mutation deactivateCustomerSubscription($reason: String) {
              deactivateCustomerSubscription(reason: $reason) {
                name
                status
                activeFrom
                paidTill
                nextPaymentDate
                recentPayments {
                  id
                  status
                  createdAt
                  total
                }
              }
            }
          `
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { deactivateCustomerSubscription } = data
  if (!deactivateCustomerSubscription) {
    throw new Error('Can not deactivate subscription! Please, contact us for any questions')
  }

  return deactivateCustomerSubscription
})

export const customerAccountReactivateSubscription = createAsyncThunk('login/customerAccountReactivateSubscription', async (args, thunkAPI) => {
  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
            mutation reactivateCustomerSubscription {
              reactivateCustomerSubscription {
                name
                status
                activeFrom
                paidTill
                nextPaymentDate
                recentPayments {
                  id
                  status
                  createdAt
                  total
                }
              }
            }
          `
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { reactivateCustomerSubscription } = data
  if (!reactivateCustomerSubscription) {
    throw new Error('Can not reactivate subscription! Please, contact us for any questions')
  }

  return reactivateCustomerSubscription
})

export const customerAccountCreateSubscription = createAsyncThunk('login/customerAccountCreateSubscription', async (args, thunkAPI) => {
  const state = thunkAPI.getState()

  const shippingInfo = state.ShippingPage
  const customerInfo = state.BillingPage.isBillingTheSameAsShipping ? state.ShippingPage : state.BillingPage

  const variables = {
    landingId: args.landingId,
    subscriptionId: state.HomePage.subscriptionProduct.id,
    shippingAddress: {
      address: shippingInfo.address,
      address2: shippingInfo.address2,
      city: shippingInfo.city,
      state: shippingInfo.state,
      zipCode: shippingInfo.zipCode,
      countryId: shippingInfo.countryId
    },
    billingAddress: {
      address: customerInfo.address,
      address2: customerInfo.address2,
      city: customerInfo.city,
      state: customerInfo.state,
      zipCode: customerInfo.zipCode,
      countryId: customerInfo.countryId
    }
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
          mutation createCustomerSubscription($landingId: ID!, $subscriptionId: ID!, $shippingAddress: AddressInput!, $billingAddress: AddressInput) {
            createCustomerSubscription(landingId: $landingId, subscriptionId: $subscriptionId, shippingAddress: $shippingAddress, billingAddress: $billingAddress) {
              name
              status
              activeFrom
              paidTill
              nextPaymentDate
              recentPayments {
                id
                status
                createdAt
                total
              }
            }
          }
        `,
      variables
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { createCustomerSubscription } = data
  if (!createCustomerSubscription) {
    throw new Error('Can not create subscription! Please, contact us for any questions')
  }

  return {
    ...createCustomerSubscription,
    isCreated: true
  }
})

export const customerAccountBecameVip = createAsyncThunk('cart/customerAccountBecameVip', async ({ paymentPluginSettings, paymentApiGateway, landingId }, thunkAPI) => {
  let token
  const state = thunkAPI.getState()
  const cardInfo = {
    cardNumber: state.BillingPage.cardNumber.replace(/\s/g, ''),
    expirationDate: state.BillingPage.expiryDate.replace('/', ''),
    cardCode: state.BillingPage.cvv
  }
  // Define product price from membership level
  const subscriptionProduct = state.HomePage.subscriptionProduct

  if (!subscriptionProduct) {
    throw new Error('Unknown subscription product!')
  }

  const subscriptionPrice = subscriptionProduct.prices[0]

  switch (paymentPluginSettings.type) {
    case 'Authorize': {
      const { paymentToken, errors } = await authorizeGetToken({
        paymentPluginSettings,
        paymentApiGateway,
        cardInfo
      })
      if (errors) {
        throw new Error(errors.map(item => item.text).join('\n'))
      }
      token = paymentToken
      break
    }
    default: {
      throw new Error('Payment plugin not found')
    }
  }
  const customerInfo = state.BillingPage
  const variables = {
    landingId,
    subscriptionProductData: {
      productId: subscriptionProduct.id,
      productPriceId: subscriptionPrice.id,
      quantity: 1,
      price: subscriptionPrice.price,
      discount: 0
    },
    paymentInfo: {
      cardInfo: {
        bin: cardInfo.cardNumber.substr(0, 6),
        cardNumber: cardInfo.cardNumber.substr(-4),
        expDate: state.BillingPage.expiryDate
      },
      customerInfo: {
        firstName: customerInfo.firstName?.trim(),
        lastName: customerInfo.lastName?.trim(),
        email: state.LoginPage.email?.trim()
      },
      billingAddress: {
        countryId: customerInfo.countryId,
        zipCode: customerInfo.zipCode?.trim(),
        state: customerInfo.state?.trim(),
        city: customerInfo.city?.trim(),
        address: customerInfo.address?.trim(),
        address2: customerInfo.address2?.trim()
      },
      token
    }
  }

  const headers = { 'Content-Type': 'application/json' }
  const authorizationToken = sessionStorage.getItem('accessToken')
  if (authorizationToken) {
    headers.authorization = `Bearer ${authorizationToken}`
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `
        mutation CustomerAccountBecameVip($landingId: ID!, $subscriptionProductData: CartProductV2Input!, $paymentInfo: PaymentInfoInput!) {
          customerAccountBecameVip(landingId: $landingId, subscriptionProductData: $subscriptionProductData, paymentInfo: $paymentInfo) {
            name
            status
            activeFrom
            paidTill
            nextPaymentDate
            recentPayments {
              id
              createdAt
              status
              total
            }
          }
        }
        `,
      variables
    })
  })

  const { data, errors } = await response.json()

  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  if (!data) {
    throw new Error("Can't change password!")
  }

  const { customerAccountBecameVip } = data
  if (!customerAccountBecameVip) {
    throw new Error('Can not became vip!')
  }

  return {
    subscription: customerAccountBecameVip,
    address: {
      countryId: customerInfo.countryId,
      zipCode: customerInfo.zipCode?.trim(),
      state: customerInfo.state?.trim(),
      city: customerInfo.city?.trim(),
      address: customerInfo.address?.trim(),
      address2: customerInfo.address2?.trim()
    },
    customerInfo: {
      firstName: customerInfo.firstName?.trim(),
      lastName: customerInfo.lastName?.trim()
    },
    isSaved: true
  }
})
