import { createAsyncThunk } from '@reduxjs/toolkit'

export const addUpsellToOrder = createAsyncThunk('cart/addUpsellToOrder', async (args, thunkAPI) => {
  const {
    BillingPage: { orderId, token },
    UpgradePage: { selectedProductPrice }
  } = thunkAPI.getState()

  if (!selectedProductPrice) {
    throw new Error("Can't find correct product price!")
  }

  const headers = { 'Content-Type': 'application/json' }
  const authorizationToken = sessionStorage.getItem('accessToken')
  if (authorizationToken) {
    headers.authorization = `Bearer ${authorizationToken}`
  }

  const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: `
        mutation AddUpsellToOrderV2($orderId: ID!, $token: String!, $productData: ProductDataV2Input!) {
          addUpsellToOrderV2(orderId: $orderId, token: $token, productData: $productData) {
            payment {
              orderId
              token
            }
            orderData {
              orderId
              shippingFirstName
              shippingLastName
              shippingAddress
              shippingCity
              shippingState
              shippingZipCode
              shippingCountry
              billingFirstName
              billingLastName
              billingAddress
              billingCity
              billingState
              billingZipCode
              billingCountry
              customerEmail
              sum
              shipping
              processing
              total
              totalSaved
              items {
                displayName
                price
                discount
                finalPrice
                quantity
                shipping
                processing
                total
              }
            }
          }
        }
      `,
      variables: {
        orderId,
        token,
        productData: {
          productId: selectedProductPrice.productId,
          productPriceId: selectedProductPrice.id
        }
      }
    })
  })

  const { data, errors } = await response.json()
  if (errors) {
    throw new Error(errors.map(item => item.message).join('\n'))
  }

  const { addUpsellToOrderV2 } = data
  if (!addUpsellToOrderV2) {
    throw new Error('Can not add product to order!')
  }

  return {
    order: addUpsellToOrderV2.orderData,
    isSaved: true
  }
})
