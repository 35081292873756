import { createSlice } from "@reduxjs/toolkit";

const thankyouSlice = createSlice({
  name: "thankyou",
  initialState: {},
});

export const selectThankYouState = (state) => state.ThankyouPage;
export const selectors = { selectThankYouState };
export default thankyouSlice.reducer;
