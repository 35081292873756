import { createAsyncThunk } from "@reduxjs/toolkit";

export const addProductToCart = createAsyncThunk(
  "cart/addProduct",
  async ({ landingId }, thunkAPI) => {
    const {
      HomePage: {
        cart: { id: cartId, cookie },
      },
      LoginPage: { membershipInfo },
      ProductPage: { selectedProductPrice: productPrice },
    } = thunkAPI.getState();

    const headers = { "Content-Type": "application/json" };
    const authorizationToken = sessionStorage.getItem("accessToken");
    if (authorizationToken) {
      headers.authorization = `Bearer ${authorizationToken}`;
    }

    // Define product price from membership level
    let price = productPrice.price;
    let discount = productPrice.discount;

    if (["TRIAL", "ACTIVE", "EXPIRING"].includes(membershipInfo.status)) {
      price = productPrice.memberPrice;
      discount = 0;
    }

    const response = await fetch(
      `${process.env.BACKEND_API}/graphql`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          query: `
        mutation AddProductToCartV2($landingInfo: LandingInfoInput! ,$cartProductData: CartProductV2Input!) {
          addProductToCartV2(landingInfo: $landingInfo, cartProductData: $cartProductData) {
            id
            cookie
            
            customerId
            customerEmail
            customerFirstName
            customerLastName

            shippingFirstName
            shippingLastName
            shippingAddress
            shippingCity
            shippingState
            shippingZipCode
            shippingCountry

            billingFirstName
            billingLastName
            billingAddress
            billingCity
            billingState
            billingZipCode
            billingCountry

            items {
              id
              productId
              productPriceId
              displayName
              sku
              quantity
              price
              discount
              finalPrice
              sum
              shipping
              processing
              total
              attributes {
                attribute
                value
              }
            }
            sum
            shipping
            processing
            total
            comment
          }
        }
      `,
          variables: {
            landingInfo: {
              landingId,
              route: "/product",
              cartId,
              cookie,
            },
            cartProductData: {
              productId: productPrice.productId,
              productPriceId: productPrice.id,
              quantity: 1,
              price,
              discount,
            },
          },
        }),
      }
    );

    const { data, errors } = await response.json();

    if (errors) {
      throw new Error(errors.map((item) => item.message).join("\n"));
    }

    if (!data) {
      throw new Error("Backend response does not contain answer body!");
    }

    const { addProductToCartV2 } = data;
    if (!addProductToCartV2) {
      throw new Error("Can not add product to cart!");
    }

    return {
      cart: addProductToCartV2,
      isSaved: true,
    };
  }
);
