import { createSlice } from "@reduxjs/toolkit";
import { addUpsellToOrder } from "./actions";

const upsellSlice = createSlice({
  name: "upsell",
  initialState: {
    prices: [],
    UIState: {
      error: undefined,
      isLoading: false,
    },
  },
  reducers: {
    setPrices: (state, action) => {
      const { prices } = action.payload
      state.prices = prices
    },
  },
  extraReducers: {
    [addUpsellToOrder.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [addUpsellToOrder.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [addUpsellToOrder.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    },
  },
});

export const actionCreators = upsellSlice.actions;
export const selectUpsellState = (state) => state.UpsellPage;
export const selectors = {
  selectUpsellState,
};

export default upsellSlice.reducer;
