import { createSlice, current } from "@reduxjs/toolkit";
import * as merchxHelpers from "assets/merchxHelpers";
import { addProductToCart } from "./actions";

const getIsValid = validation => {
  const { isSizeSelected, isPriceSelected } = validation;
  return isSizeSelected && isPriceSelected;
};

//////////////////////////////////////////////////////////////////////////////////
// REDUCER
//////////////////////////////////////////////////////////////////////////////////

const productSlice = createSlice({
  name: "product",
  initialState: {
    UIState: {
      error: undefined,
      isLoading: false
    },

    // Form
    selectedSizeOption: undefined,
    selectedProductPrice: undefined,

    // Selectors options
    sizesOptions: [],

    // Validation
    isValid: false,
    validation: {
      isSizeSelected: false,
      isPriceSelected: false
    }
  },
  reducers: {
    setProductPrice: (state, action) => {
      const { prices, productPrice } = action.payload

      state.selectedProductPrice = productPrice;
      state.selectedSizeOption = undefined;

      state.sizesOptions = merchxHelpers.getProductSizesOptions(prices, productPrice?.productId);

      state.validation.isSizeSelected = false;
      state.validation.isPriceSelected = !!action.payload;
      state.isValid = getIsValid(state.validation);
    },
    setTshirtSize: (state, action) => {
      const { sizeOption, prices } = action.payload

      state.selectedSizeOption = sizeOption;

      const newTshirtPrice = merchxHelpers.getProductPrice(prices, [
        { attribute: "variant", value: state.selectedProductPrice?.name },
        { attribute: "size", value: sizeOption?.value }
      ]);
      state.selectedProductPrice = newTshirtPrice;

      state.validation.isSizeSelected = !!action.payload.sizeOption;
      state.validation.isPriceSelected = !!newTshirtPrice;
      state.isValid = getIsValid(state.validation);
    }
  },
  extraReducers: {
    [addProductToCart.pending]: (state, action) => {
      state.UIState.isLoading = true;
      delete state.UIState.error;
    },

    [addProductToCart.fulfilled]: (state, action) => {
      state.UIState.isLoading = false;
    },

    [addProductToCart.rejected]: (state, action) => {
      state.UIState.isLoading = false;
      state.UIState.error = action.error.message;
    }
  }
});

export const actionCreators = productSlice.actions;

export const selectProductInfo = state => state.ProductPage;
export const selectError = state => state.ProductPage.UIState.error;
export const selectors = {
  selectProductInfo,
  selectError
};

export default productSlice.reducer;
